
import React from 'react'
import { Link } from 'gatsby'

import Meta from 'layout/Meta'


export default function FAQPage() {

	return <main>

		<Meta title="FAQ" />

		<h1>Frequently Asked Questions</h1>

		<h3>How do I delete my account?</h3>
		<p>Go to the <Link to='/account/'><em>Account</em> page</Link> and click on "Delete account". You will automatically leave all rooms that you joined and all your data will be removed from our severs.</p>

	</main>
}